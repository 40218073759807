import MainCustomNavbar from "../components/MainNavbar";
import { Col, Container, Row } from "react-bootstrap";
import React from "react";
import ProjectColors from "../Colors";
import LogoUnb from "../assets/logo_files/fac_unb_2.png";
import Fac from "../assets/logo_files/fac.png";
import Sec from "../assets/logo_files/sec.png";
import Con from "../assets/logo_files/connection.png";
import ProjectVersionFooter from "../components/ProjectVersionFooter";
import { useNavigate } from "react-router-dom";

export default function AboutProject() {
  const navigate = useNavigate();
  return (
    <div>
      <MainCustomNavbar activeTab4={true} />
      <main
        role="main"
        style={{
          backgroundColor: ProjectColors.getInstance().isHighContrast
            ? ProjectColors.getInstance().Black
            : ProjectColors.getInstance().Green,
        }}
      >
        <Container>
          <Row className={"align-items-center mt-0 g-5"}>
            <Col
              xs={{ span: 12 }}
              sm={{ span: 10, offset: 1 }}
              className={"mb-2"}
            >
              <h1
                style={{
                  color: ProjectColors.getInstance().isHighContrast
                    ? ProjectColors.getInstance().White
                    : ProjectColors.getInstance().DarkGreen,
                  fontSize: "1.75rem",
                  lineHeight: 1.1,
                }}
                className={"mt-sm-0"}
              >
                <span style={{ fontWeight: "bold" }}>
                  Projeto Casas de (re)existência
                </span>
                {/* cartografia artística do espaço doméstico e processos criativos */}
              </h1>
              <br />

              <p
                style={{
                  color: ProjectColors.getInstance().isHighContrast
                    ? ProjectColors.getInstance().White
                    : ProjectColors.getInstance().DarkGreen,
                  fontSize: "1.25rem",
                  lineHeight: 0.8,
                }}
              >
                <span
                  style={{
                    color: ProjectColors.getInstance().isHighContrast
                      ? ProjectColors.getInstance().White
                      : ProjectColors.getInstance().DarkGreen,
                    fontWeight: "bold",
                  }}
                >
                  Artista pesquisadora:
                </span>{" "}
                Denise Moraes (FAC-UnB)
              </p>

              <p
                style={{
                  color: ProjectColors.getInstance().isHighContrast
                    ? ProjectColors.getInstance().White
                    : ProjectColors.getInstance().DarkGreen,
                  fontSize: "1.25rem",
                  lineHeight: 1.1,
                  textAlign: "justify",
                }}
              >
                Denise Moraes é artista visual, cineasta, roteirista e
                professora do curso de Audiovisual da Universidade de Brasília.
                Graduada em Cinema pela Université Paris VIII, possui mestrado e
                doutorado em Cinema (FAC/UnB - CRIMIC/Paris-Sorbonne) e
                pós-doutorado em Artes Visuais (FAV/UFG). Em suas pesquisas, vem
                se dedicando a investigar questões poéticas do espaço doméstico,
                tendo como foco as narrativas femininas e as práticas artísticas
                autobiográficas. Coordena o projeto "CineDelas: mulheres no
                Audiovisual" e a Rede de Direção e Realização do Fórum
                Brasileiro de Ensino de Cinema e Audiovisual (FORCINE). É membro
                do Núcleo de Práticas Artísticas Autobiográficas (NuPAA/FAV/UFG)
                e conselheira da diretoria 2023-2025 da Associação Brasiliense
                de Cinema e Vídeo (ABCV). Codirigiu o filme de longa metragem "A
                Pele Morta" (Brasil-Paraguai), além de roteirizar e dirigir
                filmes de curta-metragem de ficção, entre eles "Memória de
                Elefante", premiado e exibido em festivais nacionais e
                internacionais.
              </p>
              <a
                style={{
                  color: ProjectColors.getInstance().isHighContrast
                    ? ProjectColors.getInstance().LightBlue
                    : ProjectColors.getInstance().DarkGreen,
                }}
                href="http://lattes.cnpq.br/1858458847468259"
              >
                http://lattes.cnpq.br/1858458847468259
              </a>
              <br />
              <a
                style={{
                  color: ProjectColors.getInstance().isHighContrast
                    ? ProjectColors.getInstance().LightBlue
                    : ProjectColors.getInstance().DarkGreen,
                }}
                href="https://www.denisemoraes.com/"
              >
                https://www.denisemoraes.com/
              </a>
              <br />
              <a
                style={{
                  color: ProjectColors.getInstance().isHighContrast
                    ? ProjectColors.getInstance().LightBlue
                    : ProjectColors.getInstance().DarkGreen,
                }}
                href="https://www.instagram.com/_denisemoraes_/"
              >
                @_denisemoraes_
              </a>
              <br />
              <a
                style={{
                  color: ProjectColors.getInstance().isHighContrast
                    ? ProjectColors.getInstance().LightBlue
                    : ProjectColors.getInstance().DarkGreen,
                }}
                href="https://www.instagram.com/casa.reexistencia/"
              >
                @casa.reexistencia
              </a>
              <br />

              <br />
              <br />
              <p
                style={{
                  color: ProjectColors.getInstance().isHighContrast
                    ? ProjectColors.getInstance().White
                    : ProjectColors.getInstance().DarkGreen,
                  fontSize: "1.25rem",
                  lineHeight: 0.8,
                }}
              >
                <span
                  style={{
                    color: ProjectColors.getInstance().isHighContrast
                      ? ProjectColors.getInstance().White
                      : ProjectColors.getInstance().DarkGreen,
                    fontWeight: "bold",
                  }}
                >
                  Professor supervisora:
                </span>{" "}
                Manoela dos Anjos Rodrigues (FAV-UFG)
              </p>
              <p
                style={{
                  color: ProjectColors.getInstance().isHighContrast
                    ? ProjectColors.getInstance().White
                    : ProjectColors.getInstance().DarkGreen,
                  fontSize: "1.25rem",
                  lineHeight: 0.8,
                }}
              >
                <span
                  style={{
                    color: ProjectColors.getInstance().isHighContrast
                      ? ProjectColors.getInstance().White
                      : ProjectColors.getInstance().DarkGreen,
                    fontWeight: "bold",
                  }}
                >
                  Local:
                </span>{" "}
                Programa de Pós-Graduação em Arte e Cultura Visual (PGACV/UFG)
              </p>
              <p
                style={{
                  color: ProjectColors.getInstance().isHighContrast
                    ? ProjectColors.getInstance().White
                    : ProjectColors.getInstance().DarkGreen,
                  fontSize: "1.25rem",
                  lineHeight: 0.8,
                }}
              >
                <span
                  style={{
                    color: ProjectColors.getInstance().isHighContrast
                      ? ProjectColors.getInstance().White
                      : ProjectColors.getInstance().DarkGreen,
                    fontWeight: "bold",
                  }}
                >
                  Linha de pesquisa:
                </span>{" "}
                Poéticas Artísticas e Processos de Criação
              </p>
              <p
                style={{
                  color: ProjectColors.getInstance().isHighContrast
                    ? ProjectColors.getInstance().White
                    : ProjectColors.getInstance().DarkGreen,
                  fontSize: "1.25rem",
                  lineHeight: 1.15,
                }}
              >
                <span
                  style={{
                    color: ProjectColors.getInstance().isHighContrast
                      ? ProjectColors.getInstance().White
                      : ProjectColors.getInstance().DarkGreen,
                    fontWeight: "bold",
                  }}
                >
                  Grupo de pesquisa:
                </span>{" "}
                Núcleo de Práticas Artísticas Autobiográficas da Faculdade
                (NuPAA/FAV/UFG/CNPq) -{" "}
                <a
                  style={{
                    color: ProjectColors.getInstance().isHighContrast
                      ? ProjectColors.getInstance().LightBlue
                      : ProjectColors.getInstance().DarkGreen,
                  }}
                  href="https://nupaa.org/"
                >
                  https://nupaa.org/
                </a>
              </p>
              <p
                style={{
                  color: ProjectColors.getInstance().isHighContrast
                    ? ProjectColors.getInstance().White
                    : ProjectColors.getInstance().DarkGreen,
                  fontSize: "1.25rem",
                  lineHeight: 0.8,
                }}
              >
                <span
                  style={{
                    color: ProjectColors.getInstance().isHighContrast
                      ? ProjectColors.getInstance().White
                      : ProjectColors.getInstance().DarkGreen,
                    fontWeight: "bold",
                  }}
                >
                  Patrocínio:
                </span>{" "}
                Fundo de Apoio à Cultura - FAC/GDF
              </p>
              <p
                style={{
                  color: ProjectColors.getInstance().isHighContrast
                    ? ProjectColors.getInstance().White
                    : ProjectColors.getInstance().DarkGreen,
                  fontSize: "1.25rem",
                  lineHeight: 0.8,
                }}
              >
                <span
                  style={{
                    color: ProjectColors.getInstance().isHighContrast
                      ? ProjectColors.getInstance().White
                      : ProjectColors.getInstance().DarkGreen,
                    fontWeight: "bold",
                  }}
                >
                  Período de realização:
                </span>{" "}
                2021 a 2023
              </p>

              <br />
              <br />
              <h1
                style={{
                  color: ProjectColors.getInstance().isHighContrast
                    ? ProjectColors.getInstance().White
                    : ProjectColors.getInstance().DarkGreen,
                  fontWeight: "bold",
                  fontSize: "1.5rem",
                  lineHeight: 1.1,
                }}
              >
                Equipe Técnica:
              </h1>
              <br />
              <p
                style={{
                  color: ProjectColors.getInstance().isHighContrast
                    ? ProjectColors.getInstance().White
                    : ProjectColors.getInstance().DarkGreen,
                  fontSize: "1.25rem",
                  lineHeight: 0.8,
                }}
              >
                <span
                  style={{
                    color: ProjectColors.getInstance().isHighContrast
                      ? ProjectColors.getInstance().White
                      : ProjectColors.getInstance().DarkGreen,
                    fontWeight: "bold",
                  }}
                >
                  Criação e Direção:
                </span>{" "}
                Denise Moraes
              </p>
              <p
                style={{
                  color: ProjectColors.getInstance().isHighContrast
                    ? ProjectColors.getInstance().White
                    : ProjectColors.getInstance().DarkGreen,
                  fontSize: "1.25rem",
                  lineHeight: 0.8,
                }}
              >
                <span
                  style={{
                    color: ProjectColors.getInstance().isHighContrast
                      ? ProjectColors.getInstance().White
                      : ProjectColors.getInstance().DarkGreen,
                    fontWeight: "bold",
                  }}
                >
                  Produção:
                </span>{" "}
                Solange Lima Moraes
              </p>
              <p
                style={{
                  color: ProjectColors.getInstance().isHighContrast
                    ? ProjectColors.getInstance().White
                    : ProjectColors.getInstance().DarkGreen,
                  fontSize: "1.25rem",
                  lineHeight: 0.8,
                }}
              >
                <span
                  style={{
                    color: ProjectColors.getInstance().isHighContrast
                      ? ProjectColors.getInstance().White
                      : ProjectColors.getInstance().DarkGreen,
                    fontWeight: "bold",
                  }}
                >
                  Pesquisadora adjunta (Casa de Artista):
                </span>
                Ayana Saito
              </p>

              <p
                style={{
                  color: ProjectColors.getInstance().isHighContrast
                    ? ProjectColors.getInstance().White
                    : ProjectColors.getInstance().DarkGreen,
                  fontSize: "1.25rem",
                  lineHeight: 0.8,
                }}
              >
                <span
                  style={{
                    color: ProjectColors.getInstance().isHighContrast
                      ? ProjectColors.getInstance().White
                      : ProjectColors.getInstance().DarkGreen,
                    fontWeight: "bold",
                  }}
                >
                  Direção de fotografia (Casa de Artista):
                </span>{" "}
                Emília Silberstein
              </p>
              <p
                style={{
                  color: ProjectColors.getInstance().isHighContrast
                    ? ProjectColors.getInstance().White
                    : ProjectColors.getInstance().DarkGreen,
                  fontSize: "1.25rem",
                  lineHeight: 0.8,
                }}
              >
                <span
                  style={{
                    color: ProjectColors.getInstance().isHighContrast
                      ? ProjectColors.getInstance().White
                      : ProjectColors.getInstance().DarkGreen,
                    fontWeight: "bold",
                  }}
                >
                  Técnico de Som:
                </span>{" "}
                Fernando Cavalcante
              </p>
              <p
                style={{
                  color: ProjectColors.getInstance().isHighContrast
                    ? ProjectColors.getInstance().White
                    : ProjectColors.getInstance().DarkGreen,
                  fontSize: "1.25rem",
                  lineHeight: 0.8,
                }}
              >
                <span
                  style={{
                    color: ProjectColors.getInstance().isHighContrast
                      ? ProjectColors.getInstance().White
                      : ProjectColors.getInstance().DarkGreen,
                    fontWeight: "bold",
                  }}
                >
                  Edição de imagem e som:
                </span>{" "}
                Ivan Viana
              </p>

              <p
                style={{
                  color: ProjectColors.getInstance().isHighContrast
                    ? ProjectColors.getInstance().White
                    : ProjectColors.getInstance().DarkGreen,
                  fontSize: "1.25rem",
                  lineHeight: 0.8,
                }}
              >
                <span
                  style={{
                    color: ProjectColors.getInstance().isHighContrast
                      ? ProjectColors.getInstance().White
                      : ProjectColors.getInstance().DarkGreen,
                    fontWeight: "bold",
                  }}
                >
                  Designer gráfico:
                </span>{" "}
                André Ramos
              </p>

              <p
                style={{
                  color: ProjectColors.getInstance().isHighContrast
                    ? ProjectColors.getInstance().White
                    : ProjectColors.getInstance().DarkGreen,
                  fontSize: "1.25rem",
                  lineHeight: 0.8,
                }}
              >
                <span
                  style={{
                    color: ProjectColors.getInstance().isHighContrast
                      ? ProjectColors.getInstance().White
                      : ProjectColors.getInstance().DarkGreen,
                    fontWeight: "bold",
                  }}
                >
                  Programação:
                </span>{" "}
                Gabriel Oliveira
              </p>
              <p
                style={{
                  color: ProjectColors.getInstance().isHighContrast
                    ? ProjectColors.getInstance().White
                    : ProjectColors.getInstance().DarkGreen,
                  fontSize: "1.25rem",
                  lineHeight: 0.8,
                }}
              >
                <span
                  style={{
                    color: ProjectColors.getInstance().isHighContrast
                      ? ProjectColors.getInstance().White
                      : ProjectColors.getInstance().DarkGreen,
                    fontWeight: "bold",
                  }}
                >
                  Assistente:
                </span>{" "}
                Gabriel Moraes
              </p>

              <p
                style={{
                  color: ProjectColors.getInstance().isHighContrast
                    ? ProjectColors.getInstance().White
                    : ProjectColors.getInstance().DarkGreen,
                  fontSize: "1.25rem",
                  lineHeight: 0.8,
                }}
              >
                <span
                  style={{
                    color: ProjectColors.getInstance().isHighContrast
                      ? ProjectColors.getInstance().White
                      : ProjectColors.getInstance().DarkGreen,
                    fontWeight: "bold",
                  }}
                >
                  Fotografias:
                </span>{" "}
                Denise Moraes
              </p>

              <p
                style={{
                  color: ProjectColors.getInstance().isHighContrast
                    ? ProjectColors.getInstance().White
                    : ProjectColors.getInstance().DarkGreen,
                  fontSize: "1.25rem",
                  lineHeight: 0.8,
                }}
              >
                <span
                  style={{
                    color: ProjectColors.getInstance().isHighContrast
                      ? ProjectColors.getInstance().White
                      : ProjectColors.getInstance().DarkGreen,
                    fontWeight: "bold",
                  }}
                >
                  Músicas “performances” (Casa de artista):
                </span>{" "}
                Tact - Joachim Panitz
              </p>

              <p
                style={{
                  color: ProjectColors.getInstance().isHighContrast
                    ? ProjectColors.getInstance().White
                    : ProjectColors.getInstance().DarkGreen,
                  fontSize: "1.25rem",
                  lineHeight: 0.8,
                }}
              >
                <span
                  style={{
                    color: ProjectColors.getInstance().isHighContrast
                      ? ProjectColors.getInstance().White
                      : ProjectColors.getInstance().DarkGreen,
                    fontWeight: "bold",
                  }}
                >
                  Edição adicional:
                </span>{" "}
                Clara Barros
              </p>

              <br />
              <br />
              <h1
                style={{
                  color: ProjectColors.getInstance().isHighContrast
                    ? ProjectColors.getInstance().White
                    : ProjectColors.getInstance().DarkGreen,
                  fontWeight: "bold",
                  fontSize: "1.5rem",
                  lineHeight: 1.1,
                }}
              >
                Apoio e realização:
              </h1>
              <br />
              <p
                style={{
                  color: ProjectColors.getInstance().isHighContrast
                    ? ProjectColors.getInstance().White
                    : ProjectColors.getInstance().DarkGreen,
                  fontSize: "1.25rem",
                  lineHeight: 0.8,
                }}
              >
                Fundo de Apoio à Cultura do Distrito Federal
              </p>
              <p
                style={{
                  color: ProjectColors.getInstance().isHighContrast
                    ? ProjectColors.getInstance().White
                    : ProjectColors.getInstance().DarkGreen,
                  fontSize: "1.25rem",
                  lineHeight: 0.8,
                }}
              >
                DF 150 Produções Artísticas
              </p>
              <p
                style={{
                  color: ProjectColors.getInstance().isHighContrast
                    ? ProjectColors.getInstance().White
                    : ProjectColors.getInstance().DarkGreen,
                  fontSize: "1.25rem",
                  lineHeight: 0.8,
                }}
              >
                Collectif Videocraft
              </p>

              <p
                style={{
                  color: ProjectColors.getInstance().isHighContrast
                    ? ProjectColors.getInstance().White
                    : ProjectColors.getInstance().DarkGreen,
                  fontSize: "1.25rem",
                  lineHeight: 0.8,
                }}
              >
                Sound Bloc Production
              </p>
              <p
                style={{
                  color: ProjectColors.getInstance().isHighContrast
                    ? ProjectColors.getInstance().White
                    : ProjectColors.getInstance().DarkGreen,
                  fontSize: "1.25rem",
                  lineHeight: 0.8,
                }}
              >
                <a
                  style={{
                    color: ProjectColors.getInstance().isHighContrast
                      ? ProjectColors.getInstance().LightBlue
                      : ProjectColors.getInstance().DarkGreen,
                  }}
                  href="https://pation.dev"
                >
                  Pation Software
                </a>
              </p>

              <p
                style={{
                  color: ProjectColors.getInstance().isHighContrast
                    ? ProjectColors.getInstance().White
                    : ProjectColors.getInstance().DarkGreen,
                  fontSize: "1.25rem",
                  lineHeight: 0.8,
                }}
              >
                Faculdade de Comunicação (UnB)
              </p>

              <br />
              <br />
              <h1
                style={{
                  color: ProjectColors.getInstance().isHighContrast
                    ? ProjectColors.getInstance().White
                    : ProjectColors.getInstance().DarkGreen,
                  fontWeight: "bold",
                  fontSize: "1.5rem",
                  lineHeight: 1.1,
                }}
              >
                Agradecimentos:
              </h1>
              <br />
              <p
                style={{
                  color: ProjectColors.getInstance().isHighContrast
                    ? ProjectColors.getInstance().White
                    : ProjectColors.getInstance().DarkGreen,
                  fontSize: "1.25rem",
                  lineHeight: 0.8,
                }}
              >
                Arthur Moraes
              </p>
              <p
                style={{
                  color: ProjectColors.getInstance().isHighContrast
                    ? ProjectColors.getInstance().White
                    : ProjectColors.getInstance().DarkGreen,
                  fontSize: "1.25rem",
                  lineHeight: 0.8,
                }}
              >
                Bruce Wuilloud
              </p>
              <p
                style={{
                  color: ProjectColors.getInstance().isHighContrast
                    ? ProjectColors.getInstance().White
                    : ProjectColors.getInstance().DarkGreen,
                  fontSize: "1.25rem",
                  lineHeight: 0.8,
                }}
              >
                Luiz Carlos Pontual
              </p>
              <p
                style={{
                  color: ProjectColors.getInstance().isHighContrast
                    ? ProjectColors.getInstance().White
                    : ProjectColors.getInstance().DarkGreen,
                  fontSize: "1.25rem",
                  lineHeight: 0.8,
                }}
              >
                Márcio Moraes
              </p>
              <p
                style={{
                  color: ProjectColors.getInstance().isHighContrast
                    ? ProjectColors.getInstance().White
                    : ProjectColors.getInstance().DarkGreen,
                  fontSize: "1.25rem",
                  lineHeight: 0.8,
                }}
              >
                Marco Antônio Dias
              </p>
              <p
                style={{
                  color: ProjectColors.getInstance().isHighContrast
                    ? ProjectColors.getInstance().White
                    : ProjectColors.getInstance().DarkGreen,
                  fontSize: "1.25rem",
                  lineHeight: 0.8,
                }}
              >
                Maria de Jesus
              </p>
              <p
                style={{
                  color: ProjectColors.getInstance().isHighContrast
                    ? ProjectColors.getInstance().White
                    : ProjectColors.getInstance().DarkGreen,
                  fontSize: "1.25rem",
                  lineHeight: 0.8,
                }}
              >
                Marta Imperial
              </p>
              <p
                style={{
                  color: ProjectColors.getInstance().isHighContrast
                    ? ProjectColors.getInstance().White
                    : ProjectColors.getInstance().DarkGreen,
                  fontSize: "1.25rem",
                  lineHeight: 0.8,
                }}
              >
                Matias Pereira
              </p>
              <p
                style={{
                  color: ProjectColors.getInstance().isHighContrast
                    ? ProjectColors.getInstance().White
                    : ProjectColors.getInstance().DarkGreen,
                  fontSize: "1.25rem",
                  lineHeight: 0.8,
                }}
              >
                Raquel Pacheco
              </p>
              <p
                style={{
                  color: ProjectColors.getInstance().isHighContrast
                    ? ProjectColors.getInstance().White
                    : ProjectColors.getInstance().DarkGreen,
                  fontSize: "1.25rem",
                  lineHeight: 0.8,
                }}
              >
                Solange Lima Moraes
              </p>
              <p
                style={{
                  color: ProjectColors.getInstance().isHighContrast
                    ? ProjectColors.getInstance().White
                    : ProjectColors.getInstance().DarkGreen,
                  fontSize: "1.25rem",
                  lineHeight: 0.8,
                }}
              >
                Susana Dobal
              </p>
              <p
                style={{
                  color: ProjectColors.getInstance().isHighContrast
                    ? ProjectColors.getInstance().White
                    : ProjectColors.getInstance().DarkGreen,
                  fontSize: "1.25rem",
                  lineHeight: 0.8,
                }}
              >
                Wolgrand Ribeiro
              </p>
            </Col>

            <Col xs={{ span: 12 }} sm={{ span: 10, offset: 1 }}>
              <p
                style={{
                  color: ProjectColors.getInstance().isHighContrast
                    ? ProjectColors.getInstance().White
                    : ProjectColors.getInstance().DarkGreen,
                  fontSize: "1.5rem",
                  lineHeight: 1.1,
                }}
              >
                Este projeto foi realizado com o apoio do Programa Conexão
                Cultura DF e com recursos do Fundo de Apoio à Cultura do DF.
              </p>
              <br />
              <br />
              <Row
                xs={2}
                md={4}
                className={"g-4"}
                style={{
                  backgroundColor: ProjectColors.getInstance().isHighContrast
                    ? ProjectColors.getInstance().White
                    : ProjectColors.getInstance().Green,
                }}
              >
                <Col>
                  <img
                    src={Fac}
                    alt="Logo do Fundo de Apoio à Cultura do Distrito Federal"
                    className={"img-fluid  logo float-start"}
                    style={{ paddingTop: 10 }}
                  />
                </Col>

                <Col>
                  <img
                    src={Con}
                    alt="Logo da Secretaria de Cultura e Economia Criativa"
                    className={"img-fluid  mx-auto d-block   logo"}
                    width={140}
                  />
                </Col>
                <Col>
                  <img
                    src={LogoUnb}
                    className={"img-fluid logo float-md-end float-start"}
                    alt="Logo Faculdade de Comunicação da Universidade de Brasília"
                    style={{ paddingTop: 10 }}
                  />
                </Col>
                <Col>
                  <img
                    src={Sec}
                    alt="Logo da Secretaria de Cultura e Economia Criativa"
                    className={"img-fluid  mx-auto d-block   logo"}
                  />
                </Col>
              </Row>
            </Col>

            <div></div>
          </Row>
        </Container>
      </main>

      <footer role="contentinfo">
        <ProjectVersionFooter />
      </footer>
    </div>
  );
}
