import { useNavigate } from "react-router-dom";
import MainCustomNavbar from "../../components/MainNavbar";
import { Col, Container, Row } from "react-bootstrap";
import Sidebar from "../../components/Sidebar";
import ImageAbrigar from "../../assets/mother_homes/abrigar.jpg";
import ImageArrowOrange from "../../assets/components/arrow_orange.png";
import { useEffect, useRef } from "react";
import ProjectColors from "../../Colors";

export default function Abrigar() {
  const navigate = useNavigate();

  const sliderRef = useRef<HTMLDivElement>(null);
  const sidebarRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let mouseDown = false;
    let startX: number, scrollLeft: number;
    const slider = sliderRef.current;

    if (!slider) return;

    const startDragging = (e: MouseEvent) => {
      mouseDown = true;
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    };

    const stopDragging = (e: MouseEvent) => {
      mouseDown = false;
    };

    const move = (e: MouseEvent) => {
      e.preventDefault();
      if (!mouseDown) {
        return;
      }
      const x = e.pageX - slider.offsetLeft;
      const scroll = x - startX;
      slider.scrollLeft = scrollLeft - scroll;
    };

    // Add the event listeners
    slider.addEventListener("mousemove", move, false);
    slider.addEventListener("mousedown", startDragging, false);
    slider.addEventListener("mouseup", stopDragging, false);
    slider.addEventListener("mouseleave", stopDragging, false);
  });

  // Adjust slider width according to available space
  useEffect(() => {
    const slider = sliderRef.current;
    const sidebar = sidebarRef.current;
    const container = containerRef.current;

    if (!slider || !sidebar || !container) return;

    const observerCallback: ResizeObserverCallback = (
      entries: ResizeObserverEntry[]
    ) => {
      window.requestAnimationFrame((): void | undefined => {
        if (!Array.isArray(entries) || !entries.length) {
          return;
        }
        let sidebarWidth = sidebar.getBoundingClientRect().width;
        let containerWidth = container.getBoundingClientRect().width;
        let sliderWidth = containerWidth - sidebarWidth;
        slider.style.setProperty("width", `${sliderWidth}px`, "important");
        slider.style.width = `${sliderWidth}px !important`;
      });
    };
    const resizeObserver = new ResizeObserver(observerCallback);

    resizeObserver.observe(sidebar);
    resizeObserver.observe(container);

    window.addEventListener("error", (e) => {
      if (
        e.message ===
        "ResizeObserver loop completed with undelivered notifications."
      ) {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div"
        );
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none");
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute("style", "display: none");
        }
      }
    });
  });

  const arrowScroll = (arrowNumber: number) => {
    const slider = sliderRef.current;
    const container = containerRef.current;

    if (!slider || !container) return;

    const scrollWidth = slider.scrollWidth;

    if (arrowNumber == 1) {
      const sliderWidth = slider.getBoundingClientRect().width;
      const containerWidth = container.getBoundingClientRect().width;
      slider.scroll((scrollWidth - sliderWidth) / 2, 0);
    }

    if (arrowNumber == 2) {
      slider.scroll(scrollWidth, 0);
    }
  };

  return (
    <div
      style={{
        overflow: "hidden",
        height: "100vh",
        width: "100%",
        backgroundColor: ProjectColors.getInstance().isHighContrast
          ? ProjectColors.getInstance().Black
          : ProjectColors.getInstance().VeryLightBlue,
      }}
    >
      <MainCustomNavbar activeTab0={false} activeTab2={true} />
      <main role="main">
        <Container className="memory-page-container" ref={containerRef}>
          <Row xs={2} className="mt-5 g-5 memory-page-row">
            <Col className="sidebar-col" ref={sidebarRef}>
              <Sidebar activeTab="abrigar" />
            </Col>
            <Col
              className="memory-scrollable-wrapper"
              ref={sliderRef}
              style={{
                backgroundColor: ProjectColors.getInstance().isHighContrast
                  ? ProjectColors.getInstance().DarkGreen
                  : ProjectColors.getInstance().White,
              }}
            >
              <div className="memory-scrollable-image align-content-center">
                <img
                  src={ImageAbrigar}
                  alt=""
                  className={"img-fluid float-start memory-img"}
                />
              </div>
              <div className="memory-scrollable-arrow mx-5">
                <button onClick={() => arrowScroll(1)}>
                  <img
                    src={ImageArrowOrange}
                    alt=""
                    className={"img-fluid float-start memory-arrow"}
                  />
                </button>
              </div>
              <div
                className="memory-scrollable-text"
                style={{
                  color: ProjectColors.getInstance().isHighContrast
                    ? ProjectColors.getInstance().White
                    : ProjectColors.getInstance().Black,
                }}
              >
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Curabitur porttitor arcu mattis nisi mattis viverra non at
                  magna. Aliquam sed viverra purus, quis bibendum erat. Sed
                  tincidunt, elit quis laoreet interdum, massa justo ullamcorper
                  risus, vel lobortis ex ante vitae nunc. Praesent porta est et
                  condimentum auctor. Donec elementum vulputate massa, vel
                  aliquet augue ultrices sit amet. Integer sagittis metus sed
                  ipsum auctor, vel tincidunt nunc iaculis. Integer rutrum odio
                  quis mauris semper, sit amet dignissim purus
                </p>
              </div>
              <div className="memory-scrollable-arrow mx-5">
                <button onClick={() => arrowScroll(2)}>
                  <img
                    src={ImageArrowOrange}
                    alt=""
                    className={"img-fluid float-start memory-arrow"}
                  />
                </button>
              </div>
              <div className="memory-scrollable-image align-content-center">
                <img
                  src={ImageAbrigar}
                  alt=""
                  className={"img-fluid float-start memory-img"}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </main>
    </div>
  );
}
